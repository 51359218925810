<template>
  <div class="quest" :class="state">
    <div v-if="state === 'loading'" class="place-placeholder">
      <div class="place-description-placeholder animated-background"></div>
      <div class="place-description-placeholder animated-background"></div>
    </div>
    <NavigationFrame
        v-else-if="state === 'navigate'"
        :placeGeo="place.geo"
        :okRadius="place.okRadius"
        :placeTitle="place.title"
        :placeDescription="place.description"
        :verticalPlaceImageUrl="place.verticalImageUrl"
        @in-ok-radius.once="navigatedToPlace"
        @skipQuestion="askToSkipQuestion"
    />
    <QuizFrame
        v-else-if="state === 'quiz'"
        :quiz-data="place.quiz"
        :place-title="place.title"
        :horizontalPlaceImageUrl="place.horizontalImageUrl"
        :questId="questId"
        :questionId="questionId"
        :markdownOptions="markdownOptions"
        @nextQuestion="nextQuestion"
        @finishedQuiz="finishedQuiz"
        @apierror="apierror"
        @skipQuestion="askToSkipQuestion"
    />

    <QuestionDialog
        v-if="showDialogAskForQuestionSkip"
        title="Пропустить вопрос?"
        text="Пропуск вопроса лишит Вас бонусов за прохождение квеста. Вы действительно хотите пропустить вопрос?"
        :buttons="skipQuestionButtons"
        @hide="showDialogAskForQuestionSkip = false"
        @action="skipQuestionDialogAction"
    />

  </div>
</template>

<script>
import {apiCall, makeImageUrlAbsolute} from '../../common-functions';
import NavigationFrame from './navigation/NavigationFrame.vue'
import QuizFrame from "@/components/quest/quiz/QuizFrame.vue";
import QuestionDialog from "@/components/quest/commonPageElements/QuestionDialog.vue";

export default {
  props: {
    questId: String,
    markdownOptions: Object
  },
  emits: ['setheadervalues','finishedQuiz','apierror'],
  data() {
    return {
      questionId: null,
      state: 'loading',
      place: {
        titleColor: 'white'
      },
      showDialogAskForQuestionSkip: false,
      skipQuestionButtons: [
        {
          text: 'Да',
          color: 'red'
        },
        'Нет'
      ]
    };
  },

  created() {
    this.loadQuestion();
  },

  computed: {
    cssImageUrl() {
      return 'url("' + makeImageUrlAbsolute(this.place.verticalImageUrl) + '")';
    }
  },

  methods: {
    apierror() {
      console.log('GOTAPIERROR');
      this.$emit('apierror');
    },
    navigatedToPlace() {
      this.state = 'quiz';
      const component = this;
      apiCall('/quest/' + encodeURIComponent(this.questId) + '/question/' + this.questionId + '/state', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          state: 'quiz'
        }),
      })
          .catch((e) => {
            console.error(e)
            component.$emit('apierror');
          })
      ;
    },
    nextQuestion() {
      this.loadQuestion();
    },
    finishedQuiz(questIsCompleteStatus) {
      this.state = 'finished';
      this.$emit('finishedQuiz', questIsCompleteStatus);
    },
    loadQuestion() {
      const component = this;
      component.state = 'loading';
      apiCall('/quest/' + encodeURIComponent(this.questId) + '/currentQuestion')
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Error fetching question');
          })
          .then(json => {
            this.questionId = json.id;
            this.place = json;
            component.state = json.questionState;
            if (! this.place.description) {
              this.place.description = '';
            }
            this.$emit('setheadervalues', {
              current: json.answeredQuestionsCount + 1,
              complete: json.answeredQuestionsCount,
              rest: json.totalQuestionsCount - json.answeredQuestionsCount - 1,
              skipped: json.skippedQuestionsCount
            });
          })
          .catch(e => {
            console.error(e)
            component.$emit('apierror');
          });
    },
    callApiToSkipQuestion() {
      const component = this;
      const url = '/quest/' + encodeURIComponent(this.questId) + '/question/' + this.questionId + '/skip';
      apiCall(
          url,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      )
          //todo add load animation
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Error skipping question');
          })
          .then((json) => {
            if (json.questIsComplete) {
              this.finishedQuiz();
            } else {
              this.nextQuestion();
            }
          })
          .catch(e => {
            console.error(e);
            component.$emit('apierror');
          });
    },
    askToSkipQuestion() {
      if (! window.vm.headerValues.skipped) {
        this.showDialogAskForQuestionSkip = true;
      } else {
        this.callApiToSkipQuestion();
      }
    },

    skipQuestionDialogAction(action) {
      this.showDialogAskForQuestionSkip = false;
      if (action === this.skipQuestionButtons[0].text) {
        this.callApiToSkipQuestion();
      }
    }
  },

  components: {
    QuestionDialog,
    QuizFrame,
    NavigationFrame,
  },

  mounted() {
    //todo
    // window.vm.$refs.footer.addOptionItem('Начать квест заново', () => {
    //   console.log('MENUMENU')
    // });
  },

  beforeUnmount() {
    // window.vm.$refs.footer.removeOptionItem('Начать квест заново');
  }
}
</script>

<style scoped>
@import '@/scss/loading-animation.scss';

.quest, .place-map {
  height: 100%;
  width: 100%;
}
</style>
