<template>
  <div v-if="answeredCorrectly === null" class="quiz-view">
    <div class="scrolled-info">
        <div class="place-title">{{ placeTitle }}</div>
        <div class="place-image"></div>
        <div class="question word">Вопрос:</div>
        <vue-markdown class="content-text" :source="quizData.questionText" :options="markdownOptions"/>
    </div>
    <div class="answer word">Ответ:</div>

    <div class="quiz" :class="quizData.quizType">
      <QuizTypeOptions
          v-if="quizData.quizType === 'options'"
          :options="quizData.answerOptions"
          @setanswer="setAnswer"
      />
      <QuizTypeIntInput
          v-else-if="quizData.quizType === 'int'"
          @setanswer="setAnswer"
      />
      <QuizTypeTextInput
          v-else
          @setanswer="setAnswer"
      />
    </div>

    <div v-if="quizData.quizType !== 'options'" class="sent-button-place">
      <button @click="sendAnswer(answer)">Отправить ответ</button>
    </div>
  </div>
  <div v-else class="quiz-view">
    <QuizAnswerResultFrame
        :placeTitle="placeTitle"
        :horizontalPlaceImageUrl="horizontalPlaceImageUrl"
        :answeredCorrectly="answeredCorrectly"
        :answerDescription="answerDescription"
        :longDescription="longDescription"
        :nextButtonText="nextButtonText"
        @nextQuestion="nextQuestion"
        @retryQuestion="retryQuestion"
    />
  </div>
</template>

<script>
import {apiCall, getImageUriOrDefault} from '../../../common-functions';
import QuizTypeOptions from "@/components/quest/quiz/answertypes/QuizTypeOptions.vue";
import QuizTypeIntInput from "@/components/quest/quiz/answertypes/QuizTypeIntInput.vue";
import QuizAnswerResultFrame from "@/components/quest/quiz/QuizAnswerResultFrame.vue";
import QuizTypeTextInput from "@/components/quest/quiz/answertypes/QuizTypeTextInput.vue";
import VueMarkdown from 'vue-markdown-render'

export default {
  components: {QuizTypeTextInput, QuizAnswerResultFrame, QuizTypeOptions, QuizTypeIntInput, VueMarkdown},
  props: {
    quizData: Object,
    placeTitle: String,
    horizontalPlaceImageUrl: String,
    questId: String,
    questionId: String,
    markdownOptions: Object
  },
  emits: ['finishedQuiz', 'nextQuestion', 'apierror', 'skipQuestion'],
  data() {
    return {
      answer: null,

      answeredCorrectly: null,
      invalidAnswer: null,
      correctAnswer: null,

      questIsComplete: null,
      answerDescription: '',
      longDescription: '',
      nextButtonText: 'Следующий вопрос',
      questIsCompleteStatus: {}
    };
  },

  methods: {
    setAnswer(newAnswer) {
      this.answer = newAnswer;
      console.log('this.answer',this.answer);

      if (this.quizData.quizType === 'options') {
        this.sendAnswer(this.answer);
      }
    },

    sendAnswer(answer) {
      if (typeof answer === 'undefined' || answer === '' || answer === null) {
        return;
      }

      const component = this;
      const url = '/quest/' + encodeURIComponent(this.questId) + '/question/' + this.questionId + '/answer';
      apiCall(
          url,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({answer})
          }
      )
          //todo add load animation
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Error saving question answer');
          })
          .then(json => {
            console.log(json);
            component.answeredCorrectly = json.answerIsCorrect;
            component.questIsComplete = json.questIsComplete;
            component.answerDescription = json.answerDescription;
            component.longDescription = json.longDescription;
            component.questIsCompleteStatus = json.questIsCompleteStatus;
            component.nextButtonText = json.nextButtonText;
          })
          .catch(e => {
            console.error(e);
            component.$emit('apierror');
          });
    },

    skipQuestion() {
      this.$emit('skipQuestion');
    },

    nextQuestion() {
      if (this.questIsComplete === true) {
        this.$emit('finishedQuiz', this.questIsCompleteStatus);
      } else if (this.answeredCorrectly) {
        this.$emit('nextQuestion');
      } else {
        this.skipQuestion();
      }
    },

    retryQuestion() {
      this.answeredCorrectly = null;
      this.invalidAnswer = null;
      this.correctAnswer = null;
      this.questIsComplete = null;
      this.questIsCompleteStatus = {};
    }
  },

  computed: {
    cssImageUrl() {
      return 'url("' + getImageUriOrDefault(this.horizontalPlaceImageUrl) + '")';
    }
  },

  mounted() {
    window.vm.$refs.footer.addOptionItem('Пропустить вопрос', () => {
      this.nextQuestion();
    });
    console.log('horizontalPlaceImageUrl',this.horizontalPlaceImageUrl);
  },

  beforeUnmount() {
    window.vm.$refs.footer.removeOptionItem('Пропустить вопрос');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>

<style scoped>
.quiz-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #2c2c2c;
  color: white;
}

.place-title {
  font-family: Verdana, Arial;
  font-weight: bold;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 10px;
  text-align: center;
}

.place-image {
  flex-grow: 1;
  min-height: 45%;
  background-image: v-bind('cssImageUrl');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.word {
  padding: 12px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word.question, .word.answer {
  color: #00f003;
}

.quiz.options {
  background-color: #2c2c2c;
}
.quiz {
  background-color: #8afb8c;
}

.sent-button-place {
  height: 72px;

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}
.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}
@-webkit-keyframes bang {
  to {
    box-shadow: -15px -414.6666666667px #ffa600, -217px -248.6666666667px #ff00ea, -127px -274.6666666667px #ff000d, 177px -363.6666666667px #2bff00, 247px -312.6666666667px #ff003c, 163px -140.6666666667px #ff1a00, 46px -14.6666666667px #ea00ff, 18px -383.6666666667px #f7ff00, -201px -162.6666666667px #ff9500, 9px -224.6666666667px #8400ff, 44px -223.6666666667px #00ffc8, -176px 32.3333333333px #ffae00, -111px -364.6666666667px #51ff00, -47px -366.6666666667px #ff0095, -165px -364.6666666667px #ff0077, 54px -7.6666666667px #ff9d00, 230px 67.3333333333px #00f2ff, 154px -178.6666666667px #ff2f00, 4px -84.6666666667px #62ff00, 53px -20.6666666667px #e100ff, 129px 51.3333333333px #0d00ff, 194px -25.6666666667px #ddff00, -129px -353.6666666667px #3700ff, -178px -7.6666666667px #ff0055, 44px -393.6666666667px #0059ff, 11px 41.3333333333px #00ffdd, -59px -93.6666666667px #0051ff, 202px -15.6666666667px #ff0062, -130px -235.6666666667px #c400ff, 99px -314.6666666667px #ff006f, -107px -164.6666666667px #ff00e6, -9px -261.6666666667px #ff3700, 50px -98.6666666667px #ff2b00, 69px 20.3333333333px #4400ff, 13px -201.6666666667px #ff0080, 175px -244.6666666667px #3c00ff, -104px -105.6666666667px #a6ff00, -56px -222.6666666667px #a200ff, 7px -159.6666666667px #00c4ff, 71px -67.6666666667px #1aff00, -31px -55.6666666667px #ff00b7, -201px -320.6666666667px #ff4000, -184px 38.3333333333px #9d00ff, 201px -356.6666666667px #fbff00, 51px -305.6666666667px #ff0077, -154px -388.6666666667px #ff00a2, 165px 28.3333333333px #ae00ff, 80px -246.6666666667px #ff0400, 47px -351.6666666667px #0099ff, -137px 21.3333333333px #ff9500, -178px -164.6666666667px #0011ff;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -15px -414.6666666667px #ffa600, -217px -248.6666666667px #ff00ea, -127px -274.6666666667px #ff000d, 177px -363.6666666667px #2bff00, 247px -312.6666666667px #ff003c, 163px -140.6666666667px #ff1a00, 46px -14.6666666667px #ea00ff, 18px -383.6666666667px #f7ff00, -201px -162.6666666667px #ff9500, 9px -224.6666666667px #8400ff, 44px -223.6666666667px #00ffc8, -176px 32.3333333333px #ffae00, -111px -364.6666666667px #51ff00, -47px -366.6666666667px #ff0095, -165px -364.6666666667px #ff0077, 54px -7.6666666667px #ff9d00, 230px 67.3333333333px #00f2ff, 154px -178.6666666667px #ff2f00, 4px -84.6666666667px #62ff00, 53px -20.6666666667px #e100ff, 129px 51.3333333333px #0d00ff, 194px -25.6666666667px #ddff00, -129px -353.6666666667px #3700ff, -178px -7.6666666667px #ff0055, 44px -393.6666666667px #0059ff, 11px 41.3333333333px #00ffdd, -59px -93.6666666667px #0051ff, 202px -15.6666666667px #ff0062, -130px -235.6666666667px #c400ff, 99px -314.6666666667px #ff006f, -107px -164.6666666667px #ff00e6, -9px -261.6666666667px #ff3700, 50px -98.6666666667px #ff2b00, 69px 20.3333333333px #4400ff, 13px -201.6666666667px #ff0080, 175px -244.6666666667px #3c00ff, -104px -105.6666666667px #a6ff00, -56px -222.6666666667px #a200ff, 7px -159.6666666667px #00c4ff, 71px -67.6666666667px #1aff00, -31px -55.6666666667px #ff00b7, -201px -320.6666666667px #ff4000, -184px 38.3333333333px #9d00ff, 201px -356.6666666667px #fbff00, 51px -305.6666666667px #ff0077, -154px -388.6666666667px #ff00a2, 165px 28.3333333333px #ae00ff, 80px -246.6666666667px #ff0400, 47px -351.6666666667px #0099ff, -137px 21.3333333333px #ff9500, -178px -164.6666666667px #0011ff;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -15px -414.6666666667px #ffa600, -217px -248.6666666667px #ff00ea, -127px -274.6666666667px #ff000d, 177px -363.6666666667px #2bff00, 247px -312.6666666667px #ff003c, 163px -140.6666666667px #ff1a00, 46px -14.6666666667px #ea00ff, 18px -383.6666666667px #f7ff00, -201px -162.6666666667px #ff9500, 9px -224.6666666667px #8400ff, 44px -223.6666666667px #00ffc8, -176px 32.3333333333px #ffae00, -111px -364.6666666667px #51ff00, -47px -366.6666666667px #ff0095, -165px -364.6666666667px #ff0077, 54px -7.6666666667px #ff9d00, 230px 67.3333333333px #00f2ff, 154px -178.6666666667px #ff2f00, 4px -84.6666666667px #62ff00, 53px -20.6666666667px #e100ff, 129px 51.3333333333px #0d00ff, 194px -25.6666666667px #ddff00, -129px -353.6666666667px #3700ff, -178px -7.6666666667px #ff0055, 44px -393.6666666667px #0059ff, 11px 41.3333333333px #00ffdd, -59px -93.6666666667px #0051ff, 202px -15.6666666667px #ff0062, -130px -235.6666666667px #c400ff, 99px -314.6666666667px #ff006f, -107px -164.6666666667px #ff00e6, -9px -261.6666666667px #ff3700, 50px -98.6666666667px #ff2b00, 69px 20.3333333333px #4400ff, 13px -201.6666666667px #ff0080, 175px -244.6666666667px #3c00ff, -104px -105.6666666667px #a6ff00, -56px -222.6666666667px #a200ff, 7px -159.6666666667px #00c4ff, 71px -67.6666666667px #1aff00, -31px -55.6666666667px #ff00b7, -201px -320.6666666667px #ff4000, -184px 38.3333333333px #9d00ff, 201px -356.6666666667px #fbff00, 51px -305.6666666667px #ff0077, -154px -388.6666666667px #ff00a2, 165px 28.3333333333px #ae00ff, 80px -246.6666666667px #ff0400, 47px -351.6666666667px #0099ff, -137px 21.3333333333px #ff9500, -178px -164.6666666667px #0011ff;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -15px -414.6666666667px #ffa600, -217px -248.6666666667px #ff00ea, -127px -274.6666666667px #ff000d, 177px -363.6666666667px #2bff00, 247px -312.6666666667px #ff003c, 163px -140.6666666667px #ff1a00, 46px -14.6666666667px #ea00ff, 18px -383.6666666667px #f7ff00, -201px -162.6666666667px #ff9500, 9px -224.6666666667px #8400ff, 44px -223.6666666667px #00ffc8, -176px 32.3333333333px #ffae00, -111px -364.6666666667px #51ff00, -47px -366.6666666667px #ff0095, -165px -364.6666666667px #ff0077, 54px -7.6666666667px #ff9d00, 230px 67.3333333333px #00f2ff, 154px -178.6666666667px #ff2f00, 4px -84.6666666667px #62ff00, 53px -20.6666666667px #e100ff, 129px 51.3333333333px #0d00ff, 194px -25.6666666667px #ddff00, -129px -353.6666666667px #3700ff, -178px -7.6666666667px #ff0055, 44px -393.6666666667px #0059ff, 11px 41.3333333333px #00ffdd, -59px -93.6666666667px #0051ff, 202px -15.6666666667px #ff0062, -130px -235.6666666667px #c400ff, 99px -314.6666666667px #ff006f, -107px -164.6666666667px #ff00e6, -9px -261.6666666667px #ff3700, 50px -98.6666666667px #ff2b00, 69px 20.3333333333px #4400ff, 13px -201.6666666667px #ff0080, 175px -244.6666666667px #3c00ff, -104px -105.6666666667px #a6ff00, -56px -222.6666666667px #a200ff, 7px -159.6666666667px #00c4ff, 71px -67.6666666667px #1aff00, -31px -55.6666666667px #ff00b7, -201px -320.6666666667px #ff4000, -184px 38.3333333333px #9d00ff, 201px -356.6666666667px #fbff00, 51px -305.6666666667px #ff0077, -154px -388.6666666667px #ff00a2, 165px 28.3333333333px #ae00ff, 80px -246.6666666667px #ff0400, 47px -351.6666666667px #0099ff, -137px 21.3333333333px #ff9500, -178px -164.6666666667px #0011ff;
  }
}
@keyframes bang {
  to {
    box-shadow: -15px -414.6666666667px #ffa600, -217px -248.6666666667px #ff00ea, -127px -274.6666666667px #ff000d, 177px -363.6666666667px #2bff00, 247px -312.6666666667px #ff003c, 163px -140.6666666667px #ff1a00, 46px -14.6666666667px #ea00ff, 18px -383.6666666667px #f7ff00, -201px -162.6666666667px #ff9500, 9px -224.6666666667px #8400ff, 44px -223.6666666667px #00ffc8, -176px 32.3333333333px #ffae00, -111px -364.6666666667px #51ff00, -47px -366.6666666667px #ff0095, -165px -364.6666666667px #ff0077, 54px -7.6666666667px #ff9d00, 230px 67.3333333333px #00f2ff, 154px -178.6666666667px #ff2f00, 4px -84.6666666667px #62ff00, 53px -20.6666666667px #e100ff, 129px 51.3333333333px #0d00ff, 194px -25.6666666667px #ddff00, -129px -353.6666666667px #3700ff, -178px -7.6666666667px #ff0055, 44px -393.6666666667px #0059ff, 11px 41.3333333333px #00ffdd, -59px -93.6666666667px #0051ff, 202px -15.6666666667px #ff0062, -130px -235.6666666667px #c400ff, 99px -314.6666666667px #ff006f, -107px -164.6666666667px #ff00e6, -9px -261.6666666667px #ff3700, 50px -98.6666666667px #ff2b00, 69px 20.3333333333px #4400ff, 13px -201.6666666667px #ff0080, 175px -244.6666666667px #3c00ff, -104px -105.6666666667px #a6ff00, -56px -222.6666666667px #a200ff, 7px -159.6666666667px #00c4ff, 71px -67.6666666667px #1aff00, -31px -55.6666666667px #ff00b7, -201px -320.6666666667px #ff4000, -184px 38.3333333333px #9d00ff, 201px -356.6666666667px #fbff00, 51px -305.6666666667px #ff0077, -154px -388.6666666667px #ff00a2, 165px 28.3333333333px #ae00ff, 80px -246.6666666667px #ff0400, 47px -351.6666666667px #0099ff, -137px 21.3333333333px #ff9500, -178px -164.6666666667px #0011ff;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@media (max-width: 375px) {
  .word {
    padding: 12px;
  }

  .sent-button-place {
    height: 55px;
  }
  .sent-button-place button {
    height: 40px;
  }
}

.scrolled-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px #2c2c2cc9;
  border-radius: 10px;
  background-color: white;
}
.scrolled-info::-webkit-scrollbar {
  width: 8px;
  height: 0;
}
.scrolled-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0);
}
.content-text {
  flex-grow: 1;
  overflow-y: visible;
  background-color: white;
}
.scrolled-info {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;

  /*
  box-shadow: none;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 80%), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgb(0 0 0 / 80%), rgba(0, 0, 0, 0)) 0 100%;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
  background-repeat: no-repeat;
  */
}

</style>
