<template>
  <div class="stage">
    <QuestReviewQuestionnaire
        v-if="questionnarieIsShown"
        :quest-id="questId"
        :hasBonuses="hasBonuses"
        @close="closeQuestionnarie"
    />

    <img src="/win-congratulations.png" />
    <div class="hurai">Ура!</div>
    <div class="passed">Вы прошли весь квест!</div>

    <div class="bonus">
      {{ questFinishStatus.questFinishText }}
    </div>
    <button v-if="questFinishStatus.showQuestionnarie" v-on:click="openQuestionnarie">{{ questFinishStatus.questFinishButtonText }}</button>
    <button v-if="! questFinishStatus.showQuestionnarie" v-on:click="restartQuiz">Начать заново</button>
    <button v-if="! questFinishStatus.showQuestionnarie" v-on:click="redirectToBonuses">Мои бонусы</button>
    <button v-if="! questFinishStatus.showQuestionnarie" v-on:click="openSite">Найти больше квестов на сайте</button>
  </div>
</template>

<script>
import {apiCall, getImageUriOrDefault} from '../../common-functions';
import QuestReviewQuestionnaire from "@/components/quest/misc/QuestReviewQuestionnaire.vue";

export default {
  components: {QuestReviewQuestionnaire},
  emits: ['setheadervalues'],
  props: {
    questId: String,
    showQuestionnarie: Boolean
  },
  data() {
    return {
      quizData: {},
      canFillForm: false,
      hasBonuses: true,
      questionnarieIsShown: false,
      questFinishStatus: {},
      questionnarieSent: false
    };
  },
  methods: {
    openSite() {
      window.open('https://artquestwalk.ru/', '_blank', 'noreferrer');
    },

    restartQuiz() {
      apiCall('/quest/' + encodeURIComponent(this.questId) + '/closeSession', {method: 'POST'})
          .then((response) => {
            return response.json();
          })
          .then(() => {
            window.location.reload();
          });
    },

    openQuestionnarie() {
      this.questionnarieIsShown = true;
    },

    closeQuestionnarie() {
      this.questionnarieIsShown = false;
      this.questionnarieSent = true;
      this.reloadPage();
    },

    redirectToBonuses() {
      window.location.href='/bonus';
    },

    reloadPage() {
      apiCall('/quest/' + encodeURIComponent(this.questId))
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Error fetching quest');
          })
          .then(json => {
            console.log(json);
            this.quizData = json;

            this.questFinishStatus = json.questFinishStatus;
            this.canFillForm = json.questFinishStatus.showQuestionnarie;
            this.hasBonuses = json.questFinishStatus.userCanGetPromocodes;

            this.$emit('setheadervalues', {
              current: json.questionCount,
              complete: json.questionCount,
              rest: 0,
              canGetBonuses: json.questFinishStatus.userCanGetPromocodes,
              canFillQuestionnarie: json.questFinishStatus.showQuestionnarie
            });

            if (this.showQuestionnarie && !this.questionnarieSent) {
              this.openQuestionnarie();
            }
          })
          .catch(e => {
            console.error(e);
            //todo catch 404
          });
    }
  },

  created() {
    this.reloadPage();
  },

  computed: {
    cssImageUrl() {
      return 'url("' + getImageUriOrDefault(this.quizData.startImageUrl) + '")';
    }
  },
}
</script>

<style scoped>
  .stage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    font-family: Helvetica, Arial;
    align-items: center;
  }

  .stage img {
    width: 100%;
    max-height: 50%;
  }

  .stage button {
    margin: 15px;
    position: relative;
  }

  .hurai {
    font-weight: bolder;
    font-size: 80px;
    text-transform: uppercase;


    background-color: rgb(250, 138, 0);
    background-image: linear-gradient(0deg, rgb(250, 138, 0) 0%, rgba(255,244,0,1) 50%, rgb(255, 138, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    filter: drop-shadow(0 2px 1px rgb(250, 138, 0));
  }

  .passed {
    color: rgb(250, 138, 0);
    font-weight: bold;
    font-size: 20px;
  }

  .bonus {
    font-size: 18px;
    text-align: center;
    margin: 15px;
  }

  @media (max-width: 320px) {
    .hurai {
      font-size: 45px;
    }

    .passed {
      font-size: 16px;
    }
  }
  @media (max-width: 375px) {
    .bonus {
      font-size: 14px;
      margin: 3px;
    }
    .stage button {
      font-size: 18px;
      margin: 7px;
    }
  }

</style>

